<template>
  <div class="home">
    <v-data-table :headers="headers" :items="items" :loading="loading">
      <template v-slot:item="row">
          <tr>
            <td>{{row.item.date}}</td>
            <td>{{row.item.username}}</td>
            <td>
              <v-dialog
                transition="dialog-bottom-transition"
                max-width="600"
              >
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="mx-2 blue"  elevation="0" small dark   v-bind="attrs" v-on="on">
                    view
                </v-btn>
              </template>
                <template v-slot:default="dialog">
                  <v-card>
                    <v-toolbar
                      color="blue"
                      dark
                    >View more from {{row.item.username}}
                    </v-toolbar>
                    <v-card-text class="pa-4">
                      <v-row justify="space-between" >
                        <v-col>
                          <p class="font-weight-black">Product ID:</p>
                        </v-col>
                        <v-col>
                          <p class="text-justify">{{row.item.id}}</p>
                        </v-col>
                      </v-row>
                      <v-divider></v-divider>
                      <v-row justify="space-between" >
                        <v-col>
                          <p class="font-weight-black">Telephone:</p>
                        </v-col>
                        <v-col>
                          <p class="text-justify">{{row.item.telephone}}</p>
                        </v-col>
                      </v-row>
                      <v-divider></v-divider>
                      <v-row justify="space-between" >
                        <v-col>
                          <p class="font-weight-black">Email:</p>
                        </v-col>
                        <v-col>
                          <p class="text-justify">{{row.item.email}}</p>
                        </v-col>
                      </v-row>
                      <v-divider></v-divider>
                      <v-row justify="space-between" >
                        <v-col>
                          <p class="font-weight-black">Description:</p>
                        </v-col>
                        <v-col>
                          <p class="text-justify">{{row.item.description}}</p>
                        </v-col>
                      </v-row>
                      <v-divider></v-divider>
                      <v-row justify="space-between" >
                        <v-col>
                          <p class="font-weight-black">Details:</p>
                        </v-col>
                        <v-col>
                          <p class="text-justify">{{row.item.details}}</p>
                        </v-col>
                      </v-row>
                      <v-divider></v-divider>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                      <v-btn
                        text
                        @click="dialog.value = false"
                      >Close</v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </td>
            <td>{{row.item.name}}</td>
            <td>{{row.item.quantity}}</td>
            <td>{{row.item.discount}}</td>
            <td>{{row.item.transport}}</td>
            <td>{{row.item.total}}</td>
            <td>
                <!-- <v-btn class="mx-2" :class="row.item.task?'green':'orange'" small dark elevation="0" @click="handleClick(row.item)">
                    {{row.item.task?'complete':'pending'}}
                </v-btn> -->
                <v-checkbox
                  :input-value="row.item.task"
                  :disabled="row.item.task"
                  @click="handleClick(row.item)"
                ></v-checkbox>
            </td>
          </tr>
      </template>
    </v-data-table>
    <!-- start snackbar box -->
    <v-snackbar
      v-model="snackbar"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <!-- End of snackbar -->
  </div>
</template>

<script>
import {getDatabase,get,ref,set} from 'firebase/database'
export default {
  name: 'Home',
  data(){
    return{
      myOrders:[],
      dataRef:getDatabase(),
      snackbar: false,
      text:"order has been completed trevis",
      loading:false,
      headers:[
        {text:'Date',value:'date'},
        {text:"User's Name",value:'username'},
        {text:'Details',value:''},
        {text:'Product',value:'name'},
        {text:'Quantity',value:'quantity'},
        {text:'Discount',value:'discount'},
        {text:'Transport',value:'transport'},
        {text:'Total',value:'total'},
        {text:'Task',value:'task'},
        ],
        items:[],
    }},
    mounted(){
      this.getOrders()
    },
    methods:{
      handleClick(event){
        event.task = !event.task
        this.updateTask(event)
      },
      updateTask(object){
        set(ref(this.dataRef,'/form/'+object.key+'/task'),object.task)
        .then(()=>{
          this.snackbar= true
          this.text="task has been updated"
        }).catch(error=>{
          this.snackbar=true
          this.text = "something went wrong"
        })
      },
      getOrders(){
        this.loading=true
        get(ref(this.dataRef,'/form'))
        .then(snapshot=>{
          if(snapshot.exists()){
            this.myOrders = snapshot.val()
            this.restructure()
          }
        }).catch(error=>{
          console.log("error message: ",error.message);
        })
      },
      restructure(){
        this.loading=false
        for(let key in this.myOrders){
          this.myOrders[key]['key'] = key
            this.myOrders[key]['products'].forEach(element=>{
            element['date'] = this.myOrders[key]['date']
            element['username']= this.myOrders[key]['name']
            element['telephone'] = this.myOrders[key]['telephone']
            element['details'] = this.myOrders[key]['details']
            element['email'] = this.myOrders[key]['email']
            element['transport'] = this.myOrders[key]['transport']
            element['total'] = this.myOrders[key]['total']
            element['task'] = this.myOrders[key]['task']
            element['key'] = this.myOrders[key]['key']
            this.items.push(element)
          })
        }
      }
    }
}
</script>
