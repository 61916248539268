<template>
  <div class="home">
    <div class="my-4 d-flex justify-end">
    <v-icon
    color="blue"
    class="mr-15"
    large
    @click="dialog=!dialog"
    >
      mdi-plus-circle
    </v-icon>
    </div>
    <v-data-table :headers="headers" :items="items" :loading="loading">
      <template v-slot:item="row">
          <tr>
            <td>{{row.item.id}}</td>
            <td>{{row.item.name}}</td>
            <td>
              <v-dialog
                transition="dialog-bottom-transition"
                max-width="600"
              >
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="mx-2 blue"  elevation="0" small dark   v-bind="attrs" v-on="on">
                    view
                </v-btn>
              </template>
                <template v-slot:default="dialog">
                  <v-card>
                    <v-toolbar
                      color="blue"
                      dark
                    >View more from {{row.item.username}}
                    </v-toolbar>
                    <v-card-text class="pa-4">
                      <v-row justify="space-between" >
                        <v-col>
                          <p class="font-weight-black">Description:</p>
                        </v-col>
                        <v-col>
                          <p class="text-justify">{{row.item.description}}</p>
                        </v-col>
                      </v-row>
                      <v-divider></v-divider>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                      <v-btn
                        text
                        @click="dialog.value = false"
                      >Close</v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </td>
            <td>{{row.item.price}}</td>
            <td>{{row.item.discount}}</td>
            <td>{{row.item.category}}</td>
            <td>{{row.item.sub}}</td>
            <td>
                <v-btn class="mx-2 red" small dark elevation="0" @click="handleClick(row.item)">
                    delete
                </v-btn>
            </td>
          </tr>
      </template>
    </v-data-table>
    <!-- start dialog box -->
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Product Profile</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-text-field label="Product Name" type="text" required></v-text-field>
            <v-text-field label="Price" type="number" required></v-text-field>
            <v-text-field label="Discount" type="number" required></v-text-field>
            <v-text-field label="Category" type="text" required></v-text-field>
            <v-text-field label="Sub-category" type="text" required></v-text-field>
            <v-file-input accept="image/*" truncate-length="15" label="image" @change="showEmail"></v-file-input>
            <v-textarea label="Describe Product" required></v-textarea>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="showEmail"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- start snackbar box -->
    <v-snackbar
      v-model="snackbar"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <!-- End of snackbar -->
  </div>
</template>

<script>
import {getDatabase,get,ref} from 'firebase/database'
export default {
  name: 'Home',
  data(){
    return{
      dataRef:getDatabase(),
      dialog:false,
      loading:false,
      imagelink:'',
      snackbar:false,
      text:'',
      headers:[
        {text:'Order No.',value:'id'},
        {text:'Name',value:'name'},
        {text:'Details',value:'details'},
        {text:'Price',value:'price'},
        {text:'Discount',value:'discount'},
        {text:'Category',value:'category'},
        {text:'Sub',value:'sub'},
        {text:'Action',value:'action'},
        ],
        items:[],
    }},
    mounted(){
      this.getProducts()
    },
    methods:{
      handleClick(event){
        event.task = true
      },
      showEmail(event){
        console.log("Image",event.name);
      },
      getProducts(){
          this.loading=true
          get(ref(this.dataRef,'/products'))
          .then((snapshot)=>{
              if(snapshot.exists()){
                  this.items = snapshot.val()
              }else{
                  this.snackbar=true
                  this.text = "data has not been found"
              }
              this.loading=false
          }).catch(err=>{
            //show a snackbar for error
            this.text="failed to load your database"
            this.snackbar=true
            this.loading=false
          })
      }
    }
}
</script>
