<template>
  <v-app id="inspire">
    <v-navigation-drawer
      v-model="drawer"
      app
      dark
    >
    <!-- start upper list -->
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            Marketplace
          </v-list-item-title>
          <v-list-item-subtitle>
            Cpanel
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <!-- end of upper list -->
      <v-divider></v-divider>
      <!-- start Navigation list -->
      <v-list-item
      v-for="item in items"
      :key="item.title"
      @click="changeState(item.state)"
      >
      <v-list-item-icon>
        <v-icon>{{ item.icon }}</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
      <!-- end of Navigation list -->
      <v-divider></v-divider>
      <!--Start Authentication -->
      <v-list-item
        link
      >
      <v-list-item-icon>
        <v-icon>mdi-account</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title @click="logoutUser">Logout</v-list-item-title>
      </v-list-item-content>

      </v-list-item>
      <!-- End of Authentication -->
    </v-navigation-drawer>

    <v-app-bar app dark>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>Application</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-avatar color="white">
        <span class="black--text text-h5">AD</span>
      </v-avatar>
    </v-app-bar>
    <v-main>
      <orders v-if="orders"/>
      <products v-else/>
    </v-main>
    <!-- start dialog box -->
    <v-dialog
      v-model="dialog"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="green"
        dark
      >
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- End of dialog -->
  </v-app>
</template>

<script>
import {getAuth,signOut} from 'firebase/auth'
import Products from '../../components/Products.vue'
import Orders from '../../components/Orders.vue'
  export default {
  components: { Orders, Products },
    data: () => ({ drawer: null,
      items:[
          { title: 'My Orders', icon: 'mdi-store-check',state:true},
          { title: 'My Products', icon: 'mdi-archive-plus',state:false},
      ],
      orders:true,
      dialog:false
    }),
    methods:{
        changeState(state){
            this.orders=state
        },
        logoutUser(){
          this.dialog = true
          signOut(getAuth()).then(()=>{
            console.log("user logged out");
            this.dialog=false
          }).catch((error)=>{
            this.dialog=false
            console.log("error when signout");
          })
        }
    }
  }
</script>