<template>
  <v-app>
    <v-card 
    class="mt-15 mx-auto pa-6"
    width="500px">
      <v-card-title class="black white--text">
        Welcome to Cpanel
      </v-card-title>
      <v-form
      ref="form"
      @submit.prevent="submit"
      >
      <v-card-text class="red darken-2 my-2 white--text" v-show="displayMessage">
        {{message}}
      </v-card-text>
        <v-text-field
        label="Email Address"
        type="email"
        :rules="rules.email"
        class="my-4"
        v-model="form.email"
        required
        ></v-text-field>
        <v-text-field
        label="Password"
        type="password"
        class="my-4"
        required
        v-model="form.password"
        :rules="rules.password"
        ></v-text-field>
        <v-btn
        class="green darken-2 white--text mx-auto d-block"
        width="200px"
        type="submit"
        :disabled="!formIsValid"
        >
          login
        </v-btn>
      </v-form>
    </v-card>
    <!-- start dialog box -->
    <v-dialog
      v-model="dialog"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="green"
        dark
      >
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- End of dialog -->
  </v-app>
</template>

<script>
import {getAuth,signInWithEmailAndPassword} from 'firebase/auth'
export default {
  data(){
    const defaultForm = Object.freeze({
      email:'',
      password:''
    })
    return{
    message:'contains an error message',
    displayMessage:false,
    text:'',
    dialog:false,
    form: Object.assign({},defaultForm),
    rules:{
      email:[val=>(val||'').length > 0 || 'This field is required'],
      password:[val=>(val||'').length > 0 || 'This field is required'],
    },
    defaultForm
  }},
  computed:{
    formIsValid(){
      return (
        this.form.email &&
        this.form.password
      )
    }
  },
  methods:{
    resetForm(){
      this.form = Object.assign({}, this.defaultForm)
      this.$refs.form.reset()
    },
    submit(){
      this.dialog=true
      signInWithEmailAndPassword(
        getAuth(),this.form.email,this.form.password
      ).then(()=>{
        this.resetForm()
        this.dialog=false
        this.displayMessage=false
        this.$router.push("/panel/admin")
      }).catch((error)=>{
        this.dialog=false
        this.displayMessage=true
        this.message="you have entered wrong information"
      })
    }
  }
}
</script>

<style scoped>
div[data-app='true']{
  background-image: url('/img/typing.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}
</style>